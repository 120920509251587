import { render, staticRenderFns } from "@ext/terms/terms_ro.html?vue&type=template&id=7543447c&external"
import script from "./TermsAndConditionsRO.vue?vue&type=script&lang=js"
export * from "./TermsAndConditionsRO.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports